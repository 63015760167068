.two-column {
    padding: 50px 25px;
    width: 100%;
    min-height: 662px;

    @include responsive(sm) {
        padding: 20px;
        min-height: unset;
    }
    

    .container {
        margin: 0 auto;
        max-width: 1440px;
        gap: 50px;
        display: flex;
        justify-content: end;
        position: relative;

        @include responsive(lg) {
            gap: 40px;
        }

        @include responsive(lg) {
            gap: 20px;
            flex-direction: column;

        }

        .content-image-wrap {
            display: flex;
            align-items: center;
            max-width: 800px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            border-radius: 80px;
            padding: 75px;


            @include responsive(lg) {
                position: relative;
                top: unset;
                left: unset;
                transform: translateY(0);
            }

            @include responsive(md) {
                padding: 25px;
                border-radius: 40px;
            }

            .sub-heading {
                position: absolute;
                font-size: 20px;
                text-transform: capitalize;
                font-family: "LabGrotesque";
                letter-spacing: 2px;
                opacity: 0.75;
                top: 75px;

                @include responsive(md) {
                    top: 25px;
                }

                @include responsive(sm) {
                    font-size: 16px;
                }
            }

            .content-wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 25px;

                .heading {
                    width: 100%;
                    font-family: 'DirtyLine';
                    font-size: 65px;
                    font-weight: 700;
                    margin-bottom: 30px;
                    line-height: 100%;

                    @include responsive(md) {
                        font-size: 50px;
                    }

                    @include responsive(sm) {
                        font-size: 40px;
                        width: 100%;
                    }

                    @include responsive(xs) {
                        font-size: 30px;
                    }
                }

                .description {
                    width: 100%;
                    font-family: 'LabGrotesque';
                    font-size: 18px;
                    line-height: 150%;

                    @include responsive(sm) {
                        font-size: 16px;
                        width: 100%;
                    }

                    @include responsive(xs) {
                        font-size: 14px;
                    }
                }
                
                .cta {
                    font-size: 20px;
                    font-family: 'DirtyLine';
                    font-weight: normal;
                    font-style: normal;
                    padding-top: 25px;
                    position: relative;

                    .svg-container {
                        width: 175px;
                        
                        @include responsive(md) {
                          width: 150px;
                        }
                        @include responsive(sm) {
                          width: 125px;
                        }
                      
                        svg {
                          width: 100%;
                          height: 100%;
                          
                          text {
                            transform-origin: center;
                            animation: rotate-text 2s linear infinite; /* Start rotating on hover */
                            animation-play-state: paused; /* By default, animation is paused */
                          }
                      
                          &:hover {
                            text {
                              animation-play-state: running; /* Start the animation when hovered */
                            }
                          }
                        }
                    }
                    &.btm-left {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: -120px;
                        left: 120px;
                        padding: 0;
                    }

                    &.btm-right {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: -120px;
                        right: 120px;
                        padding: 0;
                    }

                    &.top-left {
                        position: absolute;
                        margin-top: -120px;
                        top: 0;
                        left: 271px;
                        padding: 0;
                    }

                    &.top-right {
                        position: absolute;
                        top: 0;
                        margin-top: -120px;
                        right: 120px;
                        padding: 0;
                        
                    }
                    &.btm-left, &.btm-right, &.top-left, &.top-right{
                        @include responsive(md) {
                            top: -50px;
                            left: unset;
                            margin: unset;
                            bottom: unset;
                            right: 0;
                        }
                        @include responsive(sm) {
                            top: -25px;
                            left: unset;
                            margin: unset;
                            bottom: unset;
                            right: 0;
                        }
                        @include responsive(xs) {
                            top: unset;
                            left: unset;
                            margin: unset;
                            bottom: -75px;
                            right: 0;
                        }
                    }

                }

                &:hover {
                    &::after {
                        transition: transform 0.3s ease;
                        transform: scaleX(1);
                        opacity: 1;
                    }
                }

                @include responsive(md) {
                    margin-bottom: 5px;
                    font-size: 26px;
                }

                @include responsive(sm) {
                    font-size: 24px;
                }
            }
            .cta-svg{
                position: absolute;
                max-width: 175px;
                
                &.btm-right{
                    bottom: 0;
                    right: 150px;
                    margin-bottom: -100px;
                }
                &.btm-left{
                    bottom: 0;
                    left: 150px;
                    margin-bottom: -100px;

                }
                &.top-left{
                    top: 0;
                    left: 150px;
                    margin-top: -100px;
                }

                &.btm-left, &.btm-right, &.top-left, &.top-right{
                    @include responsive(md) {
                        max-width: 150px;
                        top: -50px;
                        left: unset;
                        margin: unset;
                        bottom: unset;
                        right: 0;
                    }
                    @include responsive(sm) {
                        max-width: 120px;
                        top: -25px;
                        left: unset;
                        margin: unset;
                        bottom: unset;
                        right: 0;
                    }
                    @include responsive(xs) {
                        max-width: 100px;
                        top: unset;
                        left: unset;
                        margin: unset;
                        bottom: -75px;
                        right: 0;
                    }
                }

                svg{
                    width: 100%;
                    height: 100%;

                    text {
                        transform-origin: center;
                        animation: rotate-text 2s linear infinite; /* Start rotating on hover */
                        animation-play-state: paused; /* By default, animation is paused */
                    }
                  
                    &:hover {
                        text {
                            animation-play-state: running; /* Start the animation when hovered */
                        }
                    }
                }
            }

            .prices {
                color: #92514d;
                font-size: 20px;
                font-family: 'LabGrotesque';
                font-weight: normal;
                font-style: normal;
                margin-top: 25px;
                width: fit-content;
                display: flex;
                flex-direction: column;
                gap: 25px;
                font-weight: bold;

                a {
                    color: inherit;
                    transition: background-color ease 0.3s;
                    padding: 15px 20px;
                    background-color: #6D3214;

                    span {
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0%;
                            transition: transform ease 0.3s, opacity ease 0.3s;
                            width: 100%;
                            height: 2px;
                            background-color: #92514d;
                            left: 0;
                            opacity: 0;
                            transform: scaleX(0);
                        }
                    }

                    &:hover {
                        span {
                            &::after {
                                opacity: 1;
                                transform: scaleX(1);
                            }
                        }
                    }
                }

                @include responsive(md) {
                    margin-bottom: 5px;
                    font-size: 18px;
                }

                @include responsive(sm) {
                    font-size: 16px;
                }
            }
            .swiper-arrows-wrap{
                display: flex; 
                align-items: center;
                margin-top: 20px;

                .swiper-prev-arrow{
                    margin-right: 20px;
                }
                .swiper-prev-arrow, .swiper-next-arrow{
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    border: 2px solid #111;
                    border-radius: 50%;
                    font-size: 20px;
                    padding: 10px;

                    @include responsive(sm){
                        font-size: 15px;
                    }

                    &.swiper-button-disabled{
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    .swiper {
        width: 100%;
        max-width: 1024px;
        margin: 0;

        @include responsive(sm) {
            width: 100%;
            gap: 15px;
        }

        &.row {
            .image-wrap {
                height: 100%;

                img {
                    height: 100%;
                    width: unset;
                }
            }
        }

        .image-wrap {
            overflow: hidden;

            img {
                width: 100%;
                border-radius: 100px;

                @include responsive(md) {
                    width: 100%;
                    border-radius: 40px;
                }
            }
        }
    }

    &.row-reverse {
        .container {
            justify-content: start;

            .content-image-wrap {
                left: unset;
                right: 0;
                align-items: flex-end;

                @include responsive(md) {
                    align-items: flex-start;
                }

                .sub-heading {
                    right: 75px;

                    @include responsive(md) {
                        right: unset;
                    }
                }

                .content-wrap {
                    text-align: right;
                    align-items: flex-end;

                    @include responsive(md) {
                        text-align: left;
                        align-items: flex-start;
                    }

                    .heading {
                        width: 100%;

                        @include responsive(sm) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.brown {
        background-color: #6D3214;

        .container {
            .content-image-wrap {
                background-color: #faf4df;

                .sub-heading {
                    color: #6D3214;
                }

                .content-wrap {

                    .heading,
                    .description,
                    .cta {
                        color: #6D3214;
                    }

                    .prices {
                        color: #6D3214;

                        a {
                            color: #faf4df;
                            background-color: #6D3214;

                            span {
                                &::after {
                                    background-color: #6D3214;
                                }
                            }
                        }
                    }
                }
                .swiper-prev-arrow, .swiper-next-arrow{
                    border-color: #6D3214;
                    color: #6D3214;
                }
            }
        }
    }

    &.green {
        background-color: #1A4A4A;

        .container {
            .content-image-wrap {
                background-color: #ff7600;

                .sub-heading {
                    color: white;
                }

                .content-wrap {

                    .heading,
                    .description,
                    .cta,
                    .prices {
                        color: #fff;
                    }

                    .prices {
                        color: #fff;

                        a {
                            color: #263A52;
                            background-color: #fff;

                            span {
                                &::after {
                                    background-color: #263A52;
                                }
                            }
                        }
                    }
                }
                .swiper-prev-arrow, .swiper-next-arrow{
                    border-color:  #1A4A4A;
                    color: #1A4A4A;
                }
            }
        }
    }

    &.white-brown {
        background-color: #faf4df;

        .container {
            .content-image-wrap {
                background-color: #6D3214;

                .sub-heading {
                    color: #faf4df;
                }

                .content-wrap {

                    .heading,
                    .description,
                    .cta {
                        color: #faf4df;
                    }

                    .prices {
                        color: #faf4df;

                        a {
                            background-color: #faf4df;
                            color: #1A4A4A;

                            span {
                                &::after {
                                    background-color: #faf4df;
                                }
                            }
                        }
                    }
                }
                .swiper-prev-arrow, .swiper-next-arrow{
                    border-color: #faf4df;
                    color: #faf4df;
                }
            }
        }
    }

    &.white-orange {
        background-color: #faf4df;

        .container {
            .content-image-wrap {
                background-color: #ff7600;

                .sub-heading {
                    color: #faf4df;
                }

                .content-wrap {

                    .heading,
                    .description,
                    .cta {
                        color: #fff;
                    }

                    .prices {
                        color: #fff;

                        a {
                            background-color: #fff;
                            color: #ff7600;

                            span {
                                &::after {
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                }
                .swiper-prev-arrow, .swiper-next-arrow{
                    border-color: #faf4df;
                    color: #faf4df;
                }
            }
        }
    }

    &.off-white {
        background-color: #faf4df;

        .container {
            .content-image-wrap {
                background-color: #1A4A4A;

                .sub-heading {
                    color: #faf4df;
                }

                .content-wrap {

                    .heading,
                    .description,
                    .cta {
                        color: #faf4df;
                    }

                    .prices {
                        color: #faf4df;

                        a {
                            background-color: #faf4df;
                            color: #1A4A4A;

                            span {
                                &::after {
                                    background-color: #faf4df;
                                }
                            }
                        }
                    }
                }
                .swiper-prev-arrow, .swiper-next-arrow{
                    border-color: #faf4df;
                    color: #faf4df;
                }
            }
        }
    }
    
    .swiper-arrow{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        position: absolute;
        top: 25%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        font-size: 20px;
        border: 1px solid #111;
        border-radius: 50%;

        @include responsive(lg){
            top: 55%;
        }
        @include responsive(md){
            top: unset;
            bottom: 25%;
            font-size: 15px;
        }
        @include responsive(xs){
            bottom: 15%;
        }

        &.right{
            right: 47%;

            @include responsive(lg){
                right: 10%;
            }
            @include responsive(md){
                right: 1%;
            }
        }
        &.left{
            left: 45%;

            @include responsive(lg){
                left: 75%;
            }
            @include responsive(md){
                left: 1%;
            }
        }
    }
}

@keyframes rotate-text {
    to {
      transform: rotate(360deg); /* Rotate to a full circle */
    }
  }