.availabilities-cta {
    background-color: #faf4df;

    .cta-wrap {
        text-align: center;
        padding: 0 25px 70px 25px;
        position: relative;
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row-reverse;
        margin-top: -45px;

        @include responsive(md) {
            max-width: 450px;
            margin-top: -35px;
        }

        @include responsive(sm) {
            max-width: unset;
        }

        .cta {
            max-width: 175px;
            width: 100%;

            @include responsive(md) {
                margin-right: 75px;
                max-width: 150px;
            }

            @include responsive(sm) {
                max-width: 125px;
                margin-right: 75px;
            }
            @include responsive(xs) {
                margin: unset;
            }

            svg {
                width: 100%;
                height: 100%;

                text {
                    transform-origin: center;
                    animation: rotate-text 2s linear infinite; /* Start rotating on hover */
                    animation-play-state: paused;
                    /* By default, animation is paused */
                }

                &:hover {
                    text {
                        animation-play-state: running;
                        /* Start the animation when hovered */
                    }
                }
            }
        }
    }
}

.availabilities {
    background-color: #faf4df;

    .availabilities-heading {
        background-color: #faf4df;
        color: #6D3214;
        font-size: 80px;
        font-weight: 700;
        font-family: "DirtyLine";
        text-align: center;
        padding-top: 50px;

        @include responsive(lg) {
            font-size: 70px;
        }

        @include responsive(md) {
            font-size: 60px;
        }

        @include responsive(sm) {
            font-size: 50px;
            padding-top: 40px;
        }

        @include responsive(xs) {
            font-size: 40px;
        }
    }

    .filterChoice {
        width: 100%;
        margin-top: 30px;
        padding: 25px 0px;
        user-select: none;

        @include responsive(md) {
            padding: 0 10px;

        }

        .container {
            display: flex;
            background: #faf4df;
            margin: 0 auto;
            max-width: 1440px;
            flex-direction: column;
            align-items: center;

            @include responsive(md) {
                padding: 0px 20px;
                text-align: center;
                align-items: center;
            }

            h1 {
                font-size: 28px;
                color: #6D3214;
                text-transform: uppercase;
                font-family: "DirtyLine";
                margin-bottom: 15px;
            }

            form {
                font-family: 'LabGrotesque';
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                @include responsive(md) {
                    gap: 5px;
                    margin: 0 auto;
                    justify-content: center;
                }


                label {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    margin: 0 15px;
                    padding: 10px 0px;
                    font-size: 18px;
                    position: relative;
                    justify-content: center;

                    @include responsive(md) {
                        margin: 0;
                        padding: 0;
                    }

                    input[type="radio"] {
                        -webkit-appearance: none;
                        appearance: none;
                        background-color: #faf4df;
                        height: 40px;
                        width: 100px;
                        border-radius: 8px;
                        transform: translateY(-0.1em);
                        cursor: pointer;
                        transition: 0.3s border ease, 0.3s background-color ease, 0.3s color ease;
                        border: 2px solid #6D3214;
                    }

                    span {
                        position: absolute;
                        color: #6D3214;
                    }

                    input[type="radio"]:checked {
                        border: none;
                        background-color: #92514d;
                    }

                    input[type="radio"]:checked+span {
                        color: #faf4df;
                    }
                }

                :nth-child(1) {
                    margin-left: 0;
                    padding-left: 0;
                }

                :nth-child(5) {
                    margin-right: 0;
                    padding-right: 0;
                }

            }
        }
    }

    .table {
        background-color: #faf4df;
        padding: 35px 40px 150px 40px;
        position: relative;
        z-index: 1;

        @include responsive(md) {
            padding: 100px 10px;
        }

        .inner {
            max-width: 1440px;
            margin: 0 auto;

            .table-group {
                margin-bottom: 50px;
                box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);

                &:last-child {
                    margin-bottom: 0px;
                }

                .table-row {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .table-row {
                display: flex;
                padding: 16px;
                align-items: center;
                border: 2px solid #6D3214;
                margin-bottom: 10px;

                @include responsive(md) {
                    flex-wrap: wrap;
                }

                &.table-head {
                    margin-bottom: 0px;
                    border: 2px solid #6D3214;
                    border-bottom: none;
                    background-color: #6D3214;

                    .residence {
                        font-family: 'DirtyLine';
                        color: white;
                    }

                    .cell {

                        text-transform: uppercase;

                    }

                    &:not(.mobile) {
                        @include responsive(md) {
                            display: none;
                        }
                    }
                }

                &.mobile {
                    display: none;
                    text-align: center;
                    border-radius: 20px 20px 0 0;

                    @include responsive(md) {
                        display: block;
                    }
                }

                .cell {
                    flex: 1;
                    font-family: 'LabGrotesque';
                    text-align: center;
                    padding: 10px 0;
                    color: #6D3214;

                    .btn {
                        border: none;
                        font-size: inherit;
                        background: none;
                        color: inherit;
                    }

                    &:not(.head) {
                        font-size: 18px;

                        @include responsive(md) {
                            font-size: 16px;
                        }
                    }

                    &.head {
                        color: white;
                        font-size: 24px;

                        @include responsive(md) {
                            font-size: 25px;
                            justify-content: center;
                        }
                    }

                    &.bed-bath {
                        flex: 1.5;
                    }

                    &.residence:not(.head) {
                        font-size: 35px;
                        font-family: 'DirtyLine';

                        span {
                            font-size: 26px;
                            margin-bottom: 8px;
                            display: none;
                            width: 100%;
                            text-align: center;

                            @include responsive(md) {
                                display: block;
                            }
                        }

                        @include responsive(md) {
                            flex: 100%;
                            padding-bottom: 15px;
                            margin-bottom: 10px;
                        }
                    }

                    span {
                        display: none;

                        @include responsive(md) {
                            display: block;
                            width: 50%;
                            text-align: left;
                            font-size: 25px;
                        }
                    }

                    &:not(.residence) {
                        @include responsive(md) {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            flex: 100%;
                        }
                    }

                    &.type {
                        @include responsive(md) {
                            display: none;
                        }
                    }

                    &.view {
                        cursor: pointer;
                        transition: 100ms;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.floor-plan a {
                        cursor: pointer;
                        color: #000;
                        text-decoration: none;
                        transition: 100ms;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}