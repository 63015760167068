#green{
    &::after{
        background-color: #1a4a4a; 
    }
}
#off-white{
    &::after{
        background-color: #faf4df;
    }
}
.none{
    &::after{
        background-color: unset;
    }
}
footer{
    background-color: #6d3214;
    padding: 40px;
    padding-top: 0;

    position: relative;
    &.parrallax{
        order: 99;
    }
    &::after{
        position: absolute;
        content: "";
        inset: 0;
        background-color: #fff;
        z-index: -1;
    }
    .inner{
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: #fff;
        border-top: #fff 2px solid;
        padding-top: 40px;

        @include responsive(sm){
            flex-wrap: wrap;
        }
        .origin-and-address{
            width: calc(50% - 200px);
            margin-bottom: 10px;
            @include responsive(sm){
                width: 100%;
                order: 2;
                margin-bottom: 0px;
            }
            .logos-wrap{
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                @include responsive(md){
                    margin-bottom: 5px;
                }
                @include responsive(sm){
                    margin-bottom:20px;
                    justify-content: center;
                }
                .exr-logo{
                    width: 80px;
                    margin-right: 15px;
                    @include responsive(lg){
                        width: 60px;
                    }
                    @include responsive(md){
                        width: 40px;
                        margin-right: 12px;

                    }
                    @include responsive(sm){
                        width: 50px;
                        margin-right: 10px;

                    }
                    img{
                        width: 100%;
                    }
                }
                .house-logo{
                    width: 70px;
                    @include responsive(lg){
                        width: 60px;
                    }
                    @include responsive(md){
                        width: 40px;
                    }
                    @include responsive(sm){
                        width: 50px;
                    }
                    img{
                        width: 100%;
                    }
                }
            }
        }
        .address-container{
            width: 100%;
            a{
                color: #fff;
                width: fit-content;
                font-size: 14px;
                font-weight: 400;
                font-family: 'LabGrotesque';
                @include responsive(md){
                    font-size: 14px;
                }
                @include responsive(sm){
                    font-size: 16px;
                }
            }
            .telephone{
                margin-top:20px;

                &:hover{
                    text-decoration: underline;
                }
            }
            &.desktop{
                @include responsive(sm){
                    display: none;
                }   
            }
            &.mobile{
                display: none;
                @include responsive(sm){
                    display: block;
                }
            }
        }
        .copyright-container{
            width:100%;
            font-family: 'LabGrotesque';
            text-align: center;
            &.desktop{
                @include responsive(sm){
                    display: none;
                }   
            }
            &.mobile{
                display: none;
                @include responsive(sm){
                    order:3;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
        .logo-and-copyright{
            text-align: center;
            @include responsive(sm){
                width: 100%;
                margin-bottom: 35px;
            }
            @include responsive(xs){
                margin-bottom: 30px;
            }
            img{
                margin-bottom: 30px;
                @include responsive(xl){
                    width: 160px;
                }
                @include responsive(md){
                    width: 120px;
                    margin-bottom: 25px;
                }
                @include responsive(sm){
                    margin-bottom: 20px;
                    width: 140px;
                }
                @include responsive(xs){
                    width: 120px;
                }
            }
           
        }
        .links{
            width: calc(50% - 200px);
            text-align: end;
            @include responsive(sm){
                width:100%;
                order: 1;
                align-self: center;
                text-align: center;
                margin-bottom: 35px;
            }
            .link{
                display: block;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 24px;
                text-decoration: none;
                font-family: 'Dirtyline';
                color: #ffffff;
                transition: 0.3s color ease;
                &:hover{
                    color: #f4e9d7;
                }
                @include responsive(xl){
                    margin-bottom: 20px;
                }
                @include responsive(lg){
                    font-size: 22px;
                }
                @include responsive(md){
                    font-size: 18px;
                    margin-bottom: 15px;
                }
                @include responsive(sm){
                    font-size: 20px;
                    margin-bottom: 16px;
                }
                @include responsive(xs){
                    font-size: 16px;
                    margin-bottom: 12px;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
}