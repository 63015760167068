header {
    width: 100%;
    background-color: #6d3214;
    transition: background-color 0.15s ease-in-out;
    z-index: 100;
    top: 50px;
    position: fixed;
    @include responsive(xs){
        top: 48px;
    }
    &.scrolled {
        transition: background-color 0.15s ease-in-out;
        background-color: #6d321499;
    }

    .menu-icon {
        width: 40px;
        height: 12.5px;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: space-between;
        align-items: flex-end;

        span {
            transition: 200ms;
            display: block;
            height: 2px;
            width: 100%;
            background-color: white;

            &:last-child {
                width: 80%;
            }
        }
    }

    .header-wrap {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 auto;
        justify-content: space-between;
        padding: 25px;

        @include responsive(md) {
            padding: 15px;            
        }

        .links {
            display: flex;
            align-items: center;
            color: white;

            @include responsive(md) {
                position: absolute;
                background-color: #6d3214;
                width: 100%;
                right: 0;
                padding: 25px 50px;
                display: none;
                transform: translateY(65px);
                align-items: center;
                justify-content: center;
                border-top: 1px solid white;
                pointer-events: none;
            }

            .inner {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;

                @include responsive(md) {
                    margin: 0 auto;
                }

                .link-group {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .link-container {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        gap: 0 30px;
                        width: 100%;
                        flex-wrap: wrap;

                        @include responsive(md) {
                            flex-direction: column;
                            gap: 0;
                        }

                        .link {
                            color: inherit;
                            cursor: pointer;
                            font-family: 'LabGrotesque';
                            text-transform: uppercase;
                            font-size: 14px;
                            padding: 10px 0px;
                            letter-spacing: 0.2em;
                            position: relative;
                            line-height: 100%;

                            &:last-child {
                                @include responsive(md) {
                                    display: none;
                                }
                            }

                            img {
                                width: 25px;
                                height: 25px;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 2px;
                                background-color: white;
                                bottom: 10px;
                                left: 0;
                                transform: scaleX(0);
                                transition: transform 0.3s ease;
                            }

                            &.active {
                                font-weight: 700;
                                font: bold;
                                text-decoration: underline;
                            }

                            &:hover {
                                font: bold;

                                &::after {
                                    transform: scaleX(1);
                                }
                            }
                            
                            &#solid{
                                padding: 10px;
                                background-color: white;
                                color: #6d3214;
                                cursor: pointer;
                                font-family: 'LabGrotesque';
                                text-transform: uppercase;
                                font-size: 14px;
                                text-align: center;
                                width: 100%;
                                line-height: 100%;
                                letter-spacing: 0.2em;
                                max-width: 180px;
                            }
                        }

                        .btn-wrap {
                            display: flex;
                            justify-content: end;
                            gap: 30px;
                            width: 100%;
                            margin-bottom: 24px;
                            @include responsive(md){
                                display: none;
                            }

                            .btn {
                                padding: 10px;
                                color: inherit;
                                outline: 2px white solid;
                                cursor: pointer;
                                font-family: 'LabGrotesque';
                                text-transform: uppercase;
                                font-size: 14px;
                                text-align: center;
                                width: 100%;
                                line-height: 100%;
                                letter-spacing: 0.2em;
                                max-width: 180px;
                                &:last-child{
                                    background-color: white;
                                    color: #6d3214;
                                }
                            }
                        }

                    }


                }

            }
        }

        .btn-wrap {
            display: none;
            font-family: 'LabGrotesque';
            align-items: start;

            @include responsive(md) {
                display: flex;
                align-items: center;
            }

            .tel {
                margin-right: 10px;
            }

            .email,
            .tel {
                color: white;
                align-items: center;
                width: fit-content;
                background-color: white;
                padding: 5px;
                border-radius: 100%;

                img {
                    width: 25px;
                    fill: #6d3214;
                    height: 25px;

                }

            }


        }

        .logo-wrap {
            display: flex;
            align-items: center;
            a {
                cursor: pointer;

                .logo {
                    width: 100px;
                }
            }
        }

        .button-wrap {
            display: none;

            @include responsive(md) {
                display: flex;
                flex-direction: row;
            }

            @include responsive(xs) {
                width: fit-content;
            }

            .menu-button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                z-index: 99;

                span {
                    user-select: none;
                    font-size: 26px;
                    color: #fff;
                    font-family: 'Dirtyline';
                    transition: 0.3s color ease;

                    &:hover {
                        color: #f4e9d7;
                    }
                }
            }
        }

    }

    &.scrolled {
        transition: background-color 0.15s ease-in-out;
        background-color: #6d321499;
    }

    &.close {
        .menu-icon {
            span {
                &:last-child {
                    width: 100%;
                }
            }
        }

        .header-wrap {

            .links {
                display: block;
                pointer-events: all;
            }
        }
    }


    @include responsive(md) {
        position: fixed;
        background-color: #6d3214;
    }
}