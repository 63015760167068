.specials{
    position: fixed;
    width: 100%;
    display: flex;
    background-color: white;
    top: 0;
    padding: 15px;
    transition: right 1s ease;
    z-index: 65;
    &.close{
        display: none;
    }
    .special-banner{
        text-align: center;
        position: relative;
        width: 100%;
        .text-wrap{
            .offer-text{
                font-family: $Lab;
                color: #6d3214;
                font-size: 16px;
                font-weight: 600;
                @include responsive(sm){
                    font-size: 14px;
                }
                @include responsive(xs){
                    font-size: 12px;
                }
            }
            
        }
    }
}