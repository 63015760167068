.home-first-block{
    background-color: #fff;
    padding: 60px 40px 40px;
    @include responsive(sm){
        padding-left: 20px;
        padding-right: 20px;
    }
    .container{
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @include responsive(sm){
            flex-direction: column;
            gap: 30px;
        }
        .content-and-image-wrap{
            width: calc(50% - 25px);
            align-self: flex-end;
            @include responsive(sm){
                width: 100%;
                align-self: unset;
            }
            .heading{
                font-family: 'DirtyLine';
                font-size: 120px;
                color: #CF7127;
                font-weight: 700;
                margin-bottom: 50px;
                @include responsive(xl){
                    font-size: 100px;
                }
                @include responsive(lg){
                    font-size: 80px;
                    margin-bottom: 45px;
                }
                @include responsive(md){
                    font-size: 60px;
                    margin-bottom: 35px;
                }
                @include responsive(sm){
                    font-size: 50px;
                    margin-bottom: 30px;
                }
                @include responsive(xs){
                    font-size: 40px;   
                }
            }
            .image-and-paragraph-wrap{
                display: flex;
                justify-content: space-between;
                @include responsive(sm){
                    display: unset;
                }
                .paragraph-wrap{
                    align-self: flex-end;
                    
                    p{
                        font-family: 'LabGrotesque';
                        font-size: 22px;
                        @include responsive(lg){
                            font-size: 20px;
                        }
                        @include responsive(md){
                            font-size: 18px;
                        }
                        @include responsive(sm){
                            font-size: 16px; 
                            margin-bottom: 30px;
                        }
                        @include responsive(xs){
                            font-size: 14px;   
                        }
                    }
                    .cta{
                        color: #CF7127;
                        font-size: 28px;
                        font-family: 'DirtyLine';
                        font-weight: normal;
                        font-style: normal;
                        margin-top: 20px;
                        &:hover{
                            text-decoration: underline;
                        }
                        @media(max-width:991px){
                            font-size: 26px;   
                        }
                        @media(max-width:767px){
                            margin-top: 0px;
                            font-size: 24px;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
        .images-wrap{
            width: calc(50% - 25px);
            border-radius: 50px;
            @include responsive(sm){
                width: 100%;
            }
            .image-wrap{
                width: 100%;
                display: flex;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    flex: 0 0 100%;
                }
            }
        }   
    }
}