.quick-navigation {
    background-color: $primary;
    padding: 40px;
    padding-bottom: 20px;
    border-radius: 50px 50px 0px 0px;
    position: relative;
    &.parrallax{
        order: 98;
    }
    &::after {
        position: absolute;
        content: "";
        inset: 0;
        z-index: -1;
    }

    .inner {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: 0 40px;

        @include responsive(md) {}

        @include responsive(sm) {
            flex-wrap: wrap;
            justify-content: center;
            padding: 0;
        }

        .next-page {
            &:hover {
                svg {
                    position: relative;
                }
            }
        }

        .next-page,
        .prev-page {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 24px;
            font-family: "LabGrotesque";
            margin: 0 10px;
            padding-bottom: 20px;
            transition: color 0.3s ease;
            &:hover {
                color: #feefe5;
                p {
                    &::after {
                        transition: transform 0.3s ease;
                        transform: scaleX(1);
                        opacity: 1;
                    }
                }
                svg {
                    animation: wobble infinite 1s;
                }
            }

            @include responsive(sm) {
                order: 2;
                font-size: 18px;
            }

            @include responsive(xs) {
                font-size: 16px;
                margin: 0;

            }

            p {
                margin: 0 10px;
                position: relative;
                &::after {
                    content: '';
                    transition: transform 0.3s ease, opacity 0.3s ease;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    right: 0;
                    background-color: #feefe5;
                    transform: scaleX(0);
                    transform-origin: center;
                    border-radius: 8px;
                    opacity: 0;


                }

                @include responsive(xs) {
                    margin: 0 6px;
                }
            }

            svg {
                width: 35px;
                height: 35px;

                @media (max-width: 991px) {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .heading-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            position: relative;
            transition: color 0.3s ease;
            color: white;


            &:hover {
                color: #feefe5;

                h2{
                    &::after {
                        transform: scaleX(1);
                        opacity: 1;
                    }
                }
            }

            @media (max-width: 991px) {

                width: 100%;


            }

            h2 {
                font-size: 64px;
                font-family: "DirtyLine";
                text-transform: capitalize;
                padding: 10px 5px;
                position: relative;
                &::after {
                    content: '';
                    transition: transform 0.3s ease, opacity 0.3s ease;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    right: 0;
                    background-color: #feefe5;
                    transform: scaleX(0);
                    transform-origin: center;
                    border-radius: 8px;
                    opacity: 0;
    
    
                }
                @media (max-width: 991px) {
                    font-size: 36px;

                }

                @include responsive(xs) {
                    font-size: 28px;
                }

            }
        }

        .prev-page {
            &:hover {
                svg {
                    animation: wobble2 infinite 1s;
                }
            }
        }
    }
}

#white {
    &::after {
        background-color: #fff;
    }
}

#brown {
    &::after {
        background-color: $primary;

    }
}

#orange {
    &::after {
        background-color: $secondary;

    }
}

#green {
    &::after {
        background-color: $darkgreen;

    }
}

#cyan {
    &::after {
        background-color: $cyan;

    }
}

#black {
    &::after {
        background-color: $black;
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@include keyframes(wobble) {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

@include keyframes(wobble2) {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}