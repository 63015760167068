.form-image {
    background-color: #1A4A4A;
    padding: 100px 40px;
    position: relative;
    z-index: 1;
    &.parrallax{
        order: 2;
    }
    @include responsive(xl) {
        padding: 80px 30px;
    }

    @include responsive(sm) {
        padding: 55px 20px;
    }

    .inner {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        gap: 50px;

        @include responsive(xl) {
            gap: 30px;
        }

        @include responsive(md) {
            flex-direction: column;
        }

        .content {
            width: 40%;
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            @include responsive(md) {
                width: 100%;
            }

            .form {

                input,
                select,
                textarea {
                    height: 66px;
                    min-height: 66px;
                    width: 100%;
                    text-align: center;
                    background: transparent;
                    border-radius: 35px;
                    border: 2px solid #f4e9d7;
                    margin-bottom: 14px;
                    padding: 0 30px;
                    font-size: 18px;
                    overflow: hidden;
                    color: #feeddd;
                    font-family: 'LabGrotesque';
                    font-weight: normal;
                    font-style: normal;

                    &:focus,
                    &:focus-visible {
                        border: none;
                        outline: none;
                        outline-offset: 0;
                        border: 2px solid #f4e9d7;
                    }

                    &::placeholder {
                        color: #feeddd;
                    }

                    option {
                        background-color: #1A4A4A;
                    }
                }

                .date {
                    min-width: calc(100% - 18px);
                    color: #feeddd;
                    text-align: center;
                }

                textarea {
                    padding: 20px 30px;
                    resize: none;
                }

                .select-wrap {
                    position: relative;

                    svg {
                        width: 20px;
                        right: 30px;
                        pointer-events: none;
                        top: calc(50% - 15px);
                        // transform: translateY(-50%);
                        position: absolute;

                        path {
                            fill: #feeddd;
                        }
                    }

                    select {
                        /* for Firefox */
                        -moz-appearance: none;
                        /* for Safari, Chrome, Opera */
                        -webkit-appearance: none;
                        color: #feeddd;

                        &::-ms-expand {
                            opacity: 0;
                        }
                    }

                    .layout {
                        text-align-last: center;

                        option {
                            text-align: center;
                            color: #f4e9d7 !important;

                            &:checked {
                                color: #f4e9d7 !important;
                            }

                            &::placeholder {
                                color: #f4e9d7 !important;
                            }

                            &::selection {
                                color: #f4e9d7 !important;
                            }
                        }
                    }
                }

                input[type="date"] {
                    color: #f4e9d7;
                }

                input[type="date"]::-webkit-inner-spin-button,
                input[type="date"]::-webkit-calendar-picker-indicator {
                    cursor: pointer;
                    -webkit-appearance: none;
                    opacity: 0;
                }
                input[type=date]:required:invalid::-webkit-datetime-edit {
                    color: transparent;
                    text-align: center;
                }

                input[type=date]:focus::-webkit-datetime-edit {
                    color: #f4e9d7 !important;
                }

                input[type="date"]::before {
                    color: #feeddd;
                    content: attr(placeholder);
                    top: calc(50% - 10px);
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                input[type="date"]:focus::before {
                    content: "" !important;
                    display: none;
                }
                

                .date-wrap {
                    position: relative;

                    svg {
                        width: 20px;
                        right: 30px;
                        pointer-events: none;
                        top: calc(50% - 6px);
                        transform: translateY(-50%);
                        position: absolute;

                        path {
                            stroke: #feeddd;
                        }
                    }
                }

                .button {
                    background-color: #feeddd;
                    color: #1A4A4A;
                    font-family: 'DirtyLine';
                    font-weight: 500;
                    font-style: normal;
                    font-size: 18px;
                    margin-top: 20px;
                    width: 100%;
                    border: 0;
                    height: 66px;
                    cursor: pointer;
                    border-radius: 60px;
                    transition: 300ms;

                    &:hover {
                        background-color: #dfd3c7;
                    }
                }

                #main_form {

                    .thankyou {
                        text-align: center;
                        margin-bottom: 20px;

                        h2 {
                            font-family: 'DirtyLine';
                            font-size: 72px;
                            line-height: 1.3em;
                            margin-bottom: 20px;
                            font-weight: 500;
                            color: #fff;

                            @include responsive(md) {
                                font-size: 65px;
                            }

                            @include responsive(sm) {
                                font-size: 48px;
                            }
                        }

                        p {
                            color: #fff;
                            font-size: 18px;
                            font-family: 'LabGrotesque';
                            line-height: 1.8em;
                            font-weight: lighter;

                            @include responsive(md) {
                                font-size: 16px;
                            }
                        }
                    }
                }

            }
            .secondary-option{
                width: 100%;
                text-align: center;
                p{
                    // margin: 15px 0;
                    color: white;
                    font-size: 24px;
                    font-family: "DirtyLine";
                }
                a{
                    background-color: #feeddd;
                    color: #1A4A4A;
                    font-family: "DirtyLine";
                    font-weight: 500;
                    font-style: normal;
                    font-size: 18px;
                    margin-top: 30px;
                    width: 100%;
                    border: 0;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    height:66px;
                    cursor: pointer;
                    border-radius: 60px;
                    transition: 300ms;
                }
            }
            .link-to-apply{
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .text{
                    color: white;
                    font-family: 'DirtyLine';
                    font-size: 24px;
                    margin-bottom: 10px;
                }
                a{
                    color: #f4e9d7;
                    font-family: 'LabGrotesque';
                    font-size: 16px;
                    margin-bottom: 10px;
                    &:hover{
                        color: #dfd3c7;
                    }
                }
            }
        }

        .image-wrap {
            width: 60%;

            @include responsive(md) {
                display: none;
            }

            img {
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}