/* @import must be at top of file, otherwise CSS will not work */

// Breakpoints Structure
$custom-breakpoints: (
    xs: (minSize: 0px, maxSize: 575px, name: 'XS'),
    sm: (minSize: 576px, maxSize: 767px, name: 'SM'),
    md: (minSize: 768px, maxSize: 991px, name: 'MD'),
    lg: (minSize: 992px, maxSize: 1279px, name: 'LG'),
    xl: (minSize: 1280px, maxSize: 1499px, name: 'XL'),
    xxl: (minSize: 1500px, maxSize: 0px, name: 'XXL'),
    xxxl: (minSize: 1599px, maxSize: 2000px, name: 'XXL'),
); 

// Colors
$primary: #6d3011;
$secondary: #cf7127;
$darkgreen: #172108;
$cyan: #1c4a4a;
$black: #071c21;

$Lab : 'LabGrotesque';
$Cako : 'DirtyLine';