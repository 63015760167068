.error {
    .background-image {
        @include responsive(md) {
            position: fixed ;
            height: 100vh ;
        }
    }
}

.banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include responsive(md) {
        padding-top: 84px;

    }

    @include responsive(sm) {
        height: auto;
    }

    &.brown {
        .inner {
            background-color: #6d3214;
            color: white;
        }
    }
    &.white {
        .inner {
            color: rgba(109, 50, 20, 1);
        }
    }
    &.green {
        .inner {
            background-color: rgba(26, 74, 74, 1);
            color: #FFF;
        }
    }
    &.white-orange {
        .inner {
            color: rgba(207, 113, 39, 1);
        }
    }
    &.white-green {
        .inner {
            color: rgba(26, 74, 74, 1);
        }
    }
    &.off-white {
        .inner {
            background-color: #faf4df;
            color: rgba(26, 74, 74, 1);
        }
    }
    &.off-white-brown {
        .inner {
            background-color: #faf4df;
            color: rgba(109, 50, 20, 1);
        }
    }
    &.orange {
        .inner {
            color: #FFF;
        }
    }
    .background-image {
        position: static;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        &.mobile-image {
            display: none;
            @include responsive(sm) {
                display: block;
                width: 100%;
                object-fit: cover;
                position: static;
            }
        }
        @include responsive(md) {
            max-height: 500px;
        }
        @include responsive (sm) {
            display: none;
        }
    }
    .inner {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        padding: 50px 40px;
        width: 100%;

        @include responsive(sm) {
            padding: 50px 10px 20px 10px;
        }

        @include responsive(xs) {
            padding: 25px 10px 20px;
        }

        .content {
            max-width: 1440px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @include responsive(xs) {
                max-width: 480px;
            }

            .heading {
                font-family: 'DirtyLine';
                font-size: 80px;
                letter-spacing: -1.8px;
                font-weight: 500;

                @include responsive(md) {
                    font-size: 60px;
                }

                @include responsive(sm) {
                    font-size: 50px;
                }

                @include responsive(xs) {
                    font-size: 28px;
                }

                span {
                    display: inline-block;
                    font-size: 40px;
                    transform: translateY(-10px);
                    z-index: 1;

                    @include responsive(md) {
                        font-size: 30px;
                    }

                    @include responsive(sm) {
                        font-size: 24px;
                        transform: translateX(0);
                    }

                    @include responsive(xs) {
                        font-size: 28px;
                    }
                }
            }

            .description {
                margin-top: 20px;
                font-size: 22px;
                font-family: 'LabGrotesque';
                font-weight: 500;
                max-width: 1500px;

                @include responsive(md) {
                    font-size: 18px;
                }

                @include responsive(sm) {
                    font-size: 16px;
                }
            }

            a {
                display: inline-block;
                font-size: 16px;
                color: #fff;
                cursor: pointer;
                font-family: 'LabGrotesque';
                border: 1px solid #fff;
                text-decoration: none;
                padding: 20px 30px;
                margin-top: 40px;

                &:hover {
                    transition: 0.3s ease;
                    color: #F4E9D7;
                    border-color: #F4E9D7;
                }
            }
        }
    }
}