
* {
    outline:none;
    margin:0;
    padding:0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }
  sub { 
    top: 0.4em; 
  }

html, body { 
    overflow-x:hidden;
}
body {
    -webkit-tap-highlight-color: transparent; 
    overflow: hidden;
}
img, svg{
  display:block;
  user-select: none;

}
*, :after, :before{
  margin:0;
  padding:0;
  box-sizing:border-box;
}
a{
  text-decoration:none;
  display:inline-block;
}
html{
  scroll-behavior: smooth;
}
.swiper-button-prev{
  color: #111;
  top: 60px;
  left: 40px;
  border: 3px solid #111;
  padding: 25px;
  border-radius: 50%;
  &::after{
    font-size: 20px!important;
    color: #111;
  }
  @include responsive(lg){
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    border: unset;
    padding: unset;
      &::after{
        font-size: 28px!important;
        color: #111;
      }
  }
}
.swiper-button-next{
  color: #111;
  top: 60px;
  right: 40px;
  border: 3px solid #111;
  padding: 25px;
  border-radius: 50%;
  &::after{
    font-size: 20px!important;
    color: #111;
  }
  @include responsive(lg){
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    border: unset;
    padding: unset;
    &::after{
      font-size: 28px!important;
      color: #111;
    }
  }

}
.swiper-pagination-bullet{
  background-color: #fff;
  @include responsive(sm){
    width: 15px;
    height: 15px;
  }
}
#root{
  display: flex;
  flex-direction: column;
}