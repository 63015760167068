.show3d-modal{
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 1111111111;
    .inner{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        .wrapper{
            position: relative;
            display: flex;
            img{
                max-width: 1000px;
                width: 100%;
                padding: 0 10px;
            }
            @include responsive(md){
                position: static;
            }
        }
        .close-show3d-modal{
            width: 30px;
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: #000000;
            border: 1px solid white;
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
            &:hover{
                scale: 1.03;
            }
            @include responsive(md){
                right: 10px;
            }
        }
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #222222;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  .modal{
    background-color: rgba($color: #000000, $alpha: 0.6);
    padding: 100px 40px;
    position: fixed;
    inset: 0;
    overflow: auto;
    z-index: 999;

    @include responsive(sm){
        padding: 100px 10px;
    }

    .wrap{
        max-width: 700px;
        margin: 0px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        svg{
            position: absolute;
            top: 0px;
            right: 20px;
            width: 25px;
            cursor: pointer;

            @include responsive(xs){
                right: 10px;
            }
        }
        .content-wrap{
            background-color: #6d3214;
            padding: 30px;
            text-align: center;
            
            h2{
                color: #cf7127;
                font-size: 46px;
                font-family: 'Dirtyline';
                font-weight: 500;
                margin-bottom: 20px;

                @include responsive(md){
                    font-size: 35px;
                }
                @include responsive(xs){
                    font-size: 28px;
                }
            }
            p{
                line-height: 150%;
                font-size: 14px;
                margin-bottom: 20px;
                font-weight: 200;
                text-align: center;
                color: #fff;
                font-family: 'LabGrotesque';

                @include responsive(xs){
                    font-size: 14px;
                }

                a{
                    display: inline-block;
                    text-decoration: none;
                    color: #cf7127;
                    margin-top: 10px;
                    transition: text-decoration 0.3s ease-in-out;

                    &:hover{
                        text-decoration: underline;
                        text-decoration-color: #cf7127;
                    }
                }
            }
            ul{
                padding-left: 30px;
                margin-bottom: 20px;

                li{    
                    color: #fff;
                    line-height: 150%;
                    font-weight: 200;
                    font-size: 14px;
                    font-family: 'LabGrotesque';
                }
            }

        }
    }
}
.extra-links{
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    max-width: 300px;
    margin-bottom: 10px;
    p,a{
        color: white;
        cursor: pointer;
        user-select: none;
        &:hover{
            text-decoration: underline;
        }
    }
}