@font-face {
    font-family: 'DirtyLine';
    src: url('../fonts/Casta-Black.otf');
    font-display: swap;
}

@font-face {
    font-family: 'LabGrotesque';
    src: url('../fonts/LabGrotesque-Regular.woff');
    font-display: swap;
}